import React, { FunctionComponent, MouseEventHandler } from 'react';

export type ButtonSize =
  | 'btn'
  | 'small-btn'
  | 'large-btn'
  | 'secondary-btn'
  | 'secondary-small-btn'
  | 'secondary-large-btn'
  | 'primary-on-color-btn'
  | 'primary-on-color-small-btn'
  | 'primary-on-color-large-btn'
  | 'circle-btn';

export type ButtonColor = 'persimmon' | 'aqua';

export const FernButton: FunctionComponent<{
  size: ButtonSize;
  color: ButtonColor;
  title: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  id?: string;
  className?: string;
  type?: 'button' | 'submit';
  fullwidth?: boolean;
  disabled?: boolean;
}> = ({
  size,
  color,
  title,
  onClick,
  id,
  className,
  type,
  fullwidth,
  disabled,
  children,
}) => {
  const fullwidthClass = fullwidth ? 'w-full' : '';

  return (
    <button
      id={id}
      className={[
        createButtonClass(size, color),
        fullwidthClass,
        className,
      ].join(' ')}
      type={type}
      title={title}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

FernButton.defaultProps = { className: '', type: 'button' };

export function createButtonClass(
  size: string | undefined,
  color: string | undefined
): string {
  return `fern-${size || 'btn'}--${color || 'persimmon'}`;
}
