import { useZipChecker } from '@custom_hooks/use_zip_checker';
import { DialogContent, DialogOverlay } from '@reach/dialog';
import { AnimatedSuccessIcon } from '@shared_components/animated_success_icon/animated_success_icon';
import { FernButton } from '@shared_components/fern-button/fern_button';
import { Close } from '@shared_components/icons/close';
import { TextField } from '@shared_components/text-field/text-field';
import { useBrand } from '@utility_functions/getBrandProps';
import { FormEvent, FunctionComponent } from 'react';

export const LocationCaptureModal: FunctionComponent<{
  show: boolean;
  onClose: () => void;
  segmentEventContext: string;
  onZipCodeWithinDeliveryArea: () => void;
  onZipCodeOutsideDeliveryArea: () => void;
}> = ({
  show,
  onClose,
  segmentEventContext,
  onZipCodeOutsideDeliveryArea,
  onZipCodeWithinDeliveryArea,
}) => {
  const {
    email,
    emailError,
    formState,
    setFormState,
    handleSubmitEmailLead,
    handleSubmitZipCheck,
    setEmail,
    setZipcode,
    zipcode,
    zipcodeError,
  } = useZipChecker(onZipCodeWithinDeliveryArea, onZipCodeOutsideDeliveryArea);
  const { brand } = useBrand();

  function closeLocationCaptureModal() {
    setFormState('unchecked');
    onClose();
  }
  const unchecked = (
    <>
      <div>
        <div className="fern-medium-header mb-30">
          Enter your zip code to confirm we are delivering to your area.
        </div>
        <Form onSubmit={handleSubmitZipCheck}>
          <div className="mb-30">
            <TextField
              labelText="ZIP Code"
              inputProps={{
                value: zipcode,
                onChange: (e) => setZipcode(e.target.value),
                type: 'number',
                autoFocus: true,
              }}
              noNumberArrows
              errorMessageText={zipcodeError}
            />
          </div>
          <FernButton
            size="btn"
            color="persimmon"
            title="Submit"
            onClick={() => {}}
            type="submit"
            disabled={zipcode.length === 0}
            fullwidth
          >
            Submit
          </FernButton>
        </Form>
      </div>
    </>
  );

  const deliverable = (
    <>
      <div className="fern-medium-header">Great! We deliver to your area.</div>
    </>
  );

  const undeliverable = (
    <>
      <div>
        <div className="fern-medium-header mb-20">
          {brand?.brandTitle} doesn't deliver to your area yet.
        </div>
        <div className="fern-large-body mb-30">
          Enter your email and we'll let you know when we're live.
        </div>

        <Form onSubmit={handleSubmitEmailLead} className="mb-30">
          <div className="mb-20">
            <TextField
              labelText="Your email address"
              inputProps={{
                value: email,
                onChange: (e) => setEmail(e.target.value),
                type: 'email',
              }}
              errorMessageText={emailError}
            ></TextField>
          </div>
          <FernButton
            size="btn"
            color="persimmon"
            title="Submit"
            disabled={email.length === 0}
            type="submit"
            fullwidth
          >
            Submit
          </FernButton>
        </Form>

        <button
          className="fern-link mb-60"
          onClick={() => {
            setFormState('unchecked');
          }}
        >
          try a different zip code
        </button>

        <div className="flex flex-col items-center gap-20 pb-90">
          <div className="fern-small-header mb-20 whitespace-nowrap">
            {brand?.brandTitle} currently delivers to:
          </div>
          <ul className="list-disc text-left w-[220px]">
            <li>
              <span className="fern-large-body">NYC area</span>
            </li>
            <li>
              <span className="fern-large-body">Southern California</span>
            </li>
            <li>
              <span className="fern-large-body">San Francisco</span>
            </li>
          </ul>
        </div>
      </div>
    </>
  );

  const leadCollected = (
    <>
      <div className="w-[330px] mx-auto">
        <div className="flex gap-x-15 mb-15">
          <div className="mb-15">
            <AnimatedSuccessIcon />
          </div>
          <div className="fern-medium-header">You&apos;re on the list!</div>
        </div>
        <div className="fern-large-body my-30">
          When we deliver to your city, we&apos;ll send you an email
          notification. In the meantime, follow us on{' '}
          <a
            className="fern-link"
            href={`https://www.instagram.com/${brand?.instagram}/`}
            target="_blank"
            rel="noreferrer"
          >
            Instagram
          </a>{' '}
          or{' '}
          <a
            className="fern-link"
            href={`https://www.facebook.com/${brand?.facebook}/`}
            target="_blank"
            rel="noreferrer"
          >
            Facebook
          </a>
          .
        </div>
        <FernButton
          size="btn"
          color="persimmon"
          className="w-full"
          title="Continue Shopping"
          onClick={closeLocationCaptureModal}
        >
          Continue Shopping
        </FernButton>
      </div>
    </>
  );

  return (
    <DialogOverlay
      isOpen={show}
      onDismiss={closeLocationCaptureModal}
      className="relative"
    >
      <DialogContent
        className="m-0 fixed inset-0 w-auto z-[1000] bg-sand p-0"
        aria-label="Enter your zip code to confirm we are delivering to your area."
      >
        <button
          className="fixed top-30 right-30"
          onClick={closeLocationCaptureModal}
          title="Close Modal"
        >
          <Close />
        </button>
        <div className="w-full h-full overflow-y-scroll flex justify-center px-20 pt-90 pb-0">
          <div className="text-center">
            {formState === 'unchecked' && unchecked}

            {formState === 'deliverable' && deliverable}

            {formState === 'undeliverable' && undeliverable}

            {formState === 'lead-collected' && leadCollected}
          </div>
        </div>
      </DialogContent>
    </DialogOverlay>
  );
};

export const Form: FunctionComponent<{
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  className?: string;
}> = ({ onSubmit, children, className = '' }) => {
  return (
    <form onSubmit={onSubmit} className={`max-w-[216px] mx-auto ${className}`}>
      {children}
    </form>
  );
};
