import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const Twitter: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path d="M5.65426 16.6363C12.4466 16.6363 16.1633 11.0036 16.1633 6.12725C16.1633 5.96879 16.1633 5.81032 16.1561 5.65186C16.8764 5.13325 17.503 4.47779 18 3.73589C17.3373 4.03121 16.6243 4.22569 15.8752 4.31933C16.6387 3.86555 17.2221 3.13806 17.503 2.27371C16.7899 2.69868 15.9976 3.0012 15.1549 3.16687C14.4778 2.44658 13.5198 2 12.461 2C10.4226 2 8.76591 3.65666 8.76591 5.69508C8.76591 5.98319 8.80192 6.26411 8.85954 6.53782C5.79112 6.38655 3.06843 4.90996 1.2461 2.67707C0.929172 3.22449 0.7491 3.85834 0.7491 4.53541C0.7491 5.81753 1.40456 6.94838 2.39136 7.61104C1.78631 7.58944 1.21729 7.42377 0.720288 7.15006C0.720288 7.16447 0.720288 7.17887 0.720288 7.20048C0.720288 8.98679 1.9952 10.485 3.68067 10.8235C3.37095 10.91 3.04682 10.9532 2.70828 10.9532C2.47059 10.9532 2.2401 10.9316 2.01681 10.8884C2.48499 12.3577 3.85354 13.4238 5.46699 13.4526C4.19928 14.4466 2.60744 15.0372 0.878752 15.0372C0.583433 15.0372 0.288115 15.0228 0 14.9868C1.62785 16.024 3.57263 16.6363 5.65426 16.6363Z" />
      </g>
    </svg>
  );
};
