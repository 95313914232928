import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const LinkedIn: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M16.6039 0H1.34626C0.598338 0 0 0.6 0 1.3V16.7C0 17.4 0.598338 18 1.34626 18H16.6537C17.4017 18 18 17.4 18 16.7V1.3C17.9501 0.6 17.3518 0 16.6039 0ZM5.33518 15.35H2.64266V6.75H5.28532V15.35H5.33518ZM3.98892 5.55C3.14127 5.55 2.44321 4.85 2.44321 4C2.44321 3.15 3.14127 2.5 3.98892 2.5C4.83657 2.5 5.53463 3.2 5.53463 4.05C5.53463 4.9 4.83657 5.55 3.98892 5.55ZM15.3075 15.35H12.6648V11.15C12.6648 10.15 12.6648 8.85 11.2687 8.85C9.87258 8.85 9.67313 9.95 9.67313 11.05V15.3H6.98061V6.75H9.52355V7.9H9.57341C9.92244 7.2 10.8199 6.5 12.1163 6.5C14.8089 6.5 15.3075 8.3 15.3075 10.6V15.35Z" />
    </svg>
  );
};
