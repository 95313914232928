import React, { FunctionComponent } from 'react';
import { SegmentService } from '../services/segment_service';
import { Instagram } from '@shared_components/icons/instagram';
import { Twitter } from '@shared_components/icons/twitter';
import { Pinterest } from '@shared_components/icons/pinterest';
import { Facebook } from '@shared_components/icons/facebook';
import { LinkedIn } from '@shared_components/icons/linked_in';
import { IconTextLink } from './icon_text_button/icon_text_button';
import link from 'next/link';
import { useBrand } from '@utility_functions/getBrandProps';

export const SocialIcons: FunctionComponent<{}> = () => {
  const { brand } = useBrand();

  const links = [
    {
      icon: <Instagram style={{ height: 20, width: 20 }} />,
      title: 'instagram',
      href: `https://www.instagram.com/${brand?.instagram}/`,
    },
    {
      icon: <Twitter style={{ height: 20, width: 20 }} />,
      title: 'twitter',
      href: `https://twitter.com/${brand?.twitter}/`,
    },
    {
      icon: <Pinterest style={{ height: 20, width: 20 }} />,
      title: 'pinterest',
      href: `https://www.pinterest.com/${brand?.pinterest}/`,
    },
    {
      icon: <Facebook style={{ height: 20, width: 20 }} />,
      title: 'facebook',
      href: `https://www.facebook.com/${brand?.facebook}/`,
    },
    {
      icon: <LinkedIn style={{ height: 20, width: 20 }} />,
      title: 'linkedin',
      href: `https://www.linkedin.com/company/${brand?.linkedin}/`,
    },
  ];

  return (
    <>
      {links.map((link, i) => (
        <IconTextLink
          key={i}
          icon={link.icon}
          href={link.href}
          segmentEventContext={`${link.title} Icon Clicked`}
          title={link.title}
          linkProps={{ target: '_blank', rel: 'noreferrer' }}
        />
      ))}
    </>
  );
};
