import React, { FunctionComponent } from 'react';
import { HardcodedItemType } from '@services/deprecated_room_types_service';
import { SegmentService } from '@services/segment_service';
import Link from 'next/link';

export const ItemTypeLink: FunctionComponent<{
  itemType: HardcodedItemType;
}> = ({ itemType }) => {
  return (
    <div className="mt-15">
      <Link
        href={`/n/item_types/${itemType.slug}/items`}
        className="fern-clean-link"
        onClick={() =>
          SegmentService.trackEngagementEvent('NavBar ItemTypeClicked', {
            item_type_slug: itemType.slug,
            item_type_name: itemType.name,
          })
        }
      >
        {itemType.name}
      </Link>
    </div>
  );
};
