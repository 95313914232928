import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';
import { GetServerSideProps, GetStaticProps } from 'next';
import getConfig from 'next/config';

export type Brand = 'fernish' | 'feather';
const defaultBrand: Brand = 'fernish';
const { publicRuntimeConfig } = getConfig();

export enum BrandName {
  Fernish = 'fernish',
  Feather = 'feather',
}

export type BrandParams = {
  name: Brand;
  brandTitle: string;
  email: string;
  accountEmail: string;
  wordmark: string;
  facebook: string;
  instagram: string;
  pinterest: string;
  twitter: string;
  linkedin: string;
  domain: string;
  isFernish?: boolean;
  isFeather?: boolean;
};

export type BrandProps = {
  brand: BrandParams;
};
export const BRAND_PROPS: Record<Brand, BrandParams> = {
  feather: {
    name: 'feather',
    brandTitle: 'Feather',
    email: 'hello@livefeather.com',
    accountEmail: 'account@fernish.com',
    wordmark: '/next_assets/brands/feather/wordmark.svg',
    facebook: 'livefeather',
    instagram: 'livefeather',
    pinterest: 'feather1478',
    twitter: 'livefeather',
    linkedin: 'feather1',
    domain: publicRuntimeConfig.featherDomain,
    isFeather: true,
  },
  fernish: {
    name: 'fernish',
    brandTitle: 'Fernish',
    email: 'info@fernish.com',
    accountEmail: 'customeraccounts@livefeather.com',
    wordmark: '/next_assets/brands/fernish/wordmark.svg',
    facebook: 'fernishliving',
    instagram: 'fernish',
    pinterest: 'fernishliving',
    twitter: 'fernishliving',
    linkedin: 'fernish',
    domain: publicRuntimeConfig.fernishDomain,
    isFernish: true,
  },
};

type GetBrandPropsContext =
  | Pick<Parameters<GetStaticProps>[0], 'params'>
  | Pick<Parameters<GetServerSideProps>[0], 'params'>;

export const getBrandProps = async ({ params = {} }: GetBrandPropsContext) => {
  const brand =
    (typeof params.brand === 'string' && BRAND_PROPS[params.brand as Brand]) ??
    null;
  return { props: { brand } };
};

export function getBrandPropsFromHost(host?: string): {
  props: {
    brand: false | BrandParams;
  };
} {
  let brandKey: Brand | undefined;
  let hostname = host;
  if (!hostname && typeof window !== 'undefined') {
    hostname = window.location.hostname;
  }
  if (hostname) {
    brandKey = (Object.keys(BRAND_PROPS) as Brand[]).find((brand) =>
      hostname!.includes(BRAND_PROPS[brand].domain)
    );
  }
  if (!brandKey) return { props: { brand: false } };

  return { props: { brand: BRAND_PROPS[brandKey] } };
}
export type RenderByBrandCallback = (props: BrandParams) => JSX.Element | null;

export type RenderByBrandObjectParams = {
  fernish?: RenderByBrandCallback | JSX.Element | null;
  feather?: RenderByBrandCallback | JSX.Element | null;
};

export type RenderByBrandParams =
  | RenderByBrandCallback
  | RenderByBrandObjectParams;

export const useBrand = () => {
  const router = useRouter();
  const domainName = router.query?.brand;
  const brandName = Array.isArray(domainName) ? domainName[0] : domainName;
  const brand = useMemo(() => {
    return brandName ? BRAND_PROPS[brandName as Brand] : null;
  }, [domainName]);

  const renderByBrand = useCallback(
    (params: RenderByBrandParams): JSX.Element | null => {
      if (typeof params === 'function') {
        return brand ? params(brand) : null;
      }

      if (params?.feather || params?.fernish) {
        const { feather = null, fernish = null } = params;

        switch (brand?.name) {
          case BrandName.Fernish:
            return typeof fernish === 'function' ? fernish(brand) : fernish;
          case BrandName.Feather:
            return typeof feather === 'function' ? feather(brand) : feather;
          default:
            return null;
        }
      }

      return null;
    },
    [brand]
  );

  return {
    brand,
    renderByBrand,
  };
};
