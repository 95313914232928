import React, { FunctionComponent, useState } from 'react';
import { SegmentService } from '@services/segment_service';
import { Collapse } from '@shared_components/collapse';
import { CollapseCaret } from '@shared_components/collapse_caret/collapse_caret';
import styles from './nav-mobile-menu.module.css';
import classNamesBind from 'classnames/bind';
const classNames = classNamesBind.bind(styles);

export const MobileMenuAccordion: FunctionComponent<{
  id: string;
  title: string;
  segmentEventContext: string;
}> = ({ id, title, segmentEventContext, children }) => {
  const [open, setOpen] = useState(false);

  function handleClick() {
    setOpen(!open);
    if (open) {
      SegmentService.trackEngagementEvent(`${segmentEventContext}Closed`, {});
    } else {
      SegmentService.trackEngagementEvent(`${segmentEventContext}Opened`, {});
    }
  }

  return (
    <>
      <button
        id={id}
        className={[
          styles['fern-mobile-menu--item-label'],
          'fern-xxlarge-body',
        ].join(' ')}
        aria-expanded={open}
        aria-controls={`${id}Submenu`}
        onClick={handleClick}
      >
        <div>{title}</div>
        <CollapseCaret expanded={open} />
      </button>
      <Collapse in={open} id={`${id}Submenu`} ariaLabelledBy={id}>
        <div className="mt-30">{children}</div>
      </Collapse>
    </>
  );
};
