import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const Pinterest: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M9.00739 0C4.03294 0 0 4.02632 0 8.9926C0 12.8043 2.37232 16.0609 5.72322 17.3709C5.64167 16.6604 5.57495 15.565 5.75287 14.7878C5.91597 14.0847 6.80559 10.3174 6.80559 10.3174C6.80559 10.3174 6.5387 9.77714 6.5387 8.9852C6.5387 7.73438 7.26522 6.80181 8.16967 6.80181C8.94067 6.80181 9.31135 7.37911 9.31135 8.06743C9.31135 8.83717 8.82206 9.99178 8.56258 11.065C8.34759 11.9605 9.01481 12.6933 9.89701 12.6933C11.4983 12.6933 12.729 11.0058 12.729 8.57812C12.729 6.42434 11.1795 4.92188 8.96291 4.92188C6.39784 4.92188 4.8929 6.83882 4.8929 8.82237C4.8929 9.5921 5.18944 10.4211 5.56012 10.8725C5.63425 10.9613 5.64167 11.0428 5.61943 11.1316C5.55271 11.4128 5.39702 12.0271 5.36737 12.153C5.3303 12.3158 5.23393 12.3528 5.06342 12.2714C3.93656 11.7459 3.23228 10.1102 3.23228 8.78536C3.23228 5.95066 5.29323 3.34539 9.18532 3.34539C12.3064 3.34539 14.738 5.56579 14.738 8.54112C14.738 11.6423 12.7809 14.1365 10.0675 14.1365C9.15566 14.1365 8.2957 13.6628 8.00657 13.1003C8.00657 13.1003 7.55435 14.8174 7.44315 15.2393C7.24298 16.0238 6.69438 17.0008 6.32371 17.6003C7.16885 17.8594 8.05847 18 8.99257 18C13.967 18 18 13.9737 18 9.0074C18.0148 4.02632 13.9818 0 9.00739 0Z" />
    </svg>
  );
};
