import React, { FunctionComponent } from 'react';
import { SegmentService } from '@services/segment_service';
import { Launch } from '@shared_components/icons/launch';
import Link from 'next/link';

export const TheCompanyLinks: FunctionComponent<{}> = () => {
  return (
    <div className="grid gap-y-10 mb-30">
      <Link
        href="/n/our-story"
        className="fern-clean-link"
        onClick={() =>
          SegmentService.trackEngagementEvent('NavBar OurStoryLinkClicked', {})
        }
      >
        Our Story
      </Link>
      <a
        className="fern-clean-link"
        href="/reviews"
        onClick={() =>
          SegmentService.trackEngagementEvent('NavBar ReviewLinkClicked', {})
        }
      >
        Reviews
      </a>
      <Link
        href="/n/faq"
        className="fern-clean-link"
        onClick={() =>
          SegmentService.trackEngagementEvent('NavBar FAQLinkClicked', {})
        }
      >
        FAQ
      </Link>
      <Link
        href="/n/blog"
        className="fern-clean-link"
        target="_blank"
        onClick={() =>
          SegmentService.trackEngagementEvent('NavBar BlogLinkClicked', {})
        }
      >
        Blog <Launch className="ml-5" />
      </Link>
    </div>
  );
};
