import {
  getLocationModalText,
  useLocationCaptureModal,
} from '@custom_hooks/use_location_capture_modal';
import { useOnMouseover } from '@custom_hooks/use_on_mouseover';
import { useOnScrollOrClick } from '@custom_hooks/use_on_scroll_or_click';
import { useSessionData } from '@custom_hooks/use_session_data';
import { SegmentService } from '@services/segment_service';
import { FernButton } from '@shared_components/fern-button/fern_button';
import { HeartOutline } from '@shared_components/icons/heart_outline';
import { User } from '@shared_components/icons/user';
import { IconTextLink } from '@shared_components/icon_text_button/icon_text_button';
import { LocationCaptureModal } from '@shared_components/location_capture_modal';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { MobileMenu } from './mobile_menu';
import { MobileMenuIcon } from './mobile_menu_icon';
import styles from './navbar.module.css';
import { NavbarLogo } from './navbar_logo';
import { TheCompanyFlyout } from './the_company_flyout';
import { TheGoodsFlyout } from './the_goods_flyout';
import { UserFlyoutMenu } from './user_flyout_menu';
import { useBrand } from '@utility_functions/getBrandProps';

const Search = dynamic<{}>(
  () => import('./search').then((module) => module.Search),
  { ssr: false }
);
const Cart = dynamic<{}>(() => import('./cart').then((module) => module.Cart), {
  ssr: false,
});
const Banner = dynamic<{}>(
  () => import('./banner').then((module) => module.Banner),
  { ssr: false }
);

export const Navbar: FunctionComponent<{ hideNav: boolean }> = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const router = useRouter();

  function openMobileMenu() {
    setMobileMenuOpen(true);
    document.body.classList.add('overflow-hidden');
  }
  function closeMobileMenu() {
    setMobileMenuOpen(false);
    document.body.classList.remove('overflow-hidden');
  }

  useEffect(() => {
    return () => {
      closeMobileMenu();
    };
  }, [router.asPath]);

  // ----------------------------------------
  // lazy load the goods flyout
  // ----------------------------------------
  const scrolled = useOnScrollOrClick();
  const { ref, mouseOvered } = useOnMouseover();

  // ----------------------------------------
  // Location Modal Stuff
  // ----------------------------------------
  const {
    openLocationModal,
    locationCaptureModal,
    handleZipCodeWithinDeliveryArea,
    handleZipCodeOutsideDeliveryArea,
  } = useLocationCaptureModal();

  const { data: sessionData } = useSessionData();
  const currentUser = sessionData?.currentUser;
  const locationModalText = getLocationModalText(sessionData);
  const { renderByBrand } = useBrand();

  const mobileLocationModalButton = (
    <>
      <FernButton
        size="secondary-btn"
        color="persimmon"
        title={locationModalText || 'Check Delivery Area'}
        onClick={() => {
          SegmentService.trackEngagementEvent(
            'Mobile NavBar Location Capture Modal Clicked',
            {}
          );
          openLocationModal('Mobile Navbar');
        }}
        fullwidth
      >
        <span>{locationModalText}</span>
      </FernButton>
    </>
  );

  const desktopLocationModalButton = (
    <>
      <FernButton
        size="secondary-btn"
        color="persimmon"
        title={locationModalText || 'Check Delivery Area'}
        onClick={() => {
          SegmentService.trackEngagementEvent(
            'Desktop NavBar Location Capture Modal Clicked',
            {}
          );
          openLocationModal('Desktop Navbar');
        }}
      >
        {/* We can't change the font size of buttons b/c of accessibility reasons */}
        {locationModalText}
      </FernButton>
    </>
  );

  // ----------------------------------------
  // left and right content
  // ----------------------------------------
  const leftColumnContent = (
    <>
      <MobileMenuIcon
        open={mobileMenuOpen}
        onClick={() => (mobileMenuOpen ? closeMobileMenu() : openMobileMenu())}
      ></MobileMenuIcon>
      <div className={`${styles['fern-desktop-menu--item']}`} ref={ref}>
        <Link
          href="/n/room_types"
          className={styles['fern-desktop-menu--item-link']}
        >
          The Goods
        </Link>
        <div
          className={`${styles['fern-nav--flyout']} ${styles['flyout-animation-inactive']}`}
        >
          {(scrolled || mouseOvered) && <TheGoodsFlyout></TheGoodsFlyout>}
        </div>
      </div>

      {renderByBrand({
        fernish: (
          <>
            <div className={`${styles['fern-desktop-menu--item']}`}>
              <Link
                href="/n/service"
                className={styles['fern-desktop-menu--item-link']}
                onClick={() =>
                  SegmentService.trackEngagementEvent(
                    'NavBar TheServiceTabClicked',
                    {}
                  )
                }
              >
                The Service
              </Link>
            </div>
            <div className={`${styles['fern-desktop-menu--item']}`}>
              <Link
                href="/n/our-story"
                className={styles['fern-desktop-menu--item-link']}
              >
                The Company
              </Link>
              <div
                className={`${styles['fern-nav--flyout']} ${styles['flyout-animation-inactive']}`}
              >
                <TheCompanyFlyout />
              </div>
            </div>
          </>
        ),
        feather: (
          <>
            <div className={`${styles['fern-desktop-menu--item']}`}>
              <Link
                href="/n/faq"
                className={styles['fern-desktop-menu--item-link']}
                onClick={() =>
                  SegmentService.trackEngagementEvent(
                    'NavBar FAQLinkClicked',
                    {}
                  )
                }
              >
                FAQ
              </Link>
            </div>
            <div className={`${styles['fern-desktop-menu--item']}`}>
              <Link
                href="https://explore.livefeather.com/"
                target="_blank"
                className={styles['fern-desktop-menu--item-link']}
              >
                Feather for Business
              </Link>
            </div>
          </>
        ),
      })}
    </>
  );

  const rightColumnContent = (
    <>
      {/* location capture modal */}
      <div className="fern-show-small-desktop-up">
        {desktopLocationModalButton}
      </div>

      {/* user menu */}
      <div className="fern-show-small-desktop-up">
        <div className={styles['fern-desktop-menu--icon-flyout-container']}>
          <IconTextLink
            className={styles['fern-nav--icon-link']}
            icon={<User style={{ width: 20, height: 20 }} />}
            // TODO - https://fernish.atlassian.net/browse/FERN-10336
            // href={currentUser ? '/n/dashboard/account_details' : '/users/sign_up'}
            href={currentUser ? '/users/edit' : '/users/sign_up'}
            segmentEventContext={'Desktop NavBar UserProfileLinkClicked'}
            title={'Create an Account'}
          ></IconTextLink>
          <UserFlyoutMenu></UserFlyoutMenu>
        </div>
      </div>

      {/* wishlist */}
      <div className="fern-show-small-desktop-up">
        <IconTextLink
          className={styles['fern-nav--icon-link']}
          icon={<HeartOutline style={{ width: 20, height: 20 }} />}
          href={'/wishlists'}
          segmentEventContext={'Navbar Wishlist Desktop Link Clicked'}
          title={'Wishlists'}
        ></IconTextLink>
      </div>

      {/* search */}
      <Search />

      {/* cart */}
      <Cart />
    </>
  );

  // ----------------------------------------
  // Render
  // ----------------------------------------
  if (props.hideNav) {
    return <NavbarTemplate />;
  } else {
    return (
      <NavbarTemplate
        leftColumnContent={leftColumnContent}
        rightColumnContent={rightColumnContent}
        extraContent={
          <>
            <MobileMenu
              open={mobileMenuOpen}
              onClose={closeMobileMenu}
              locationModalButton={mobileLocationModalButton}
            ></MobileMenu>
            <LocationCaptureModal
              show={locationCaptureModal.open}
              onClose={locationCaptureModal.closeModal}
              segmentEventContext="Desktop Location Capture Modal"
              onZipCodeWithinDeliveryArea={handleZipCodeWithinDeliveryArea}
              onZipCodeOutsideDeliveryArea={handleZipCodeOutsideDeliveryArea}
            />
          </>
        }
        banner={<Banner />}
      />
    );
  }
};

export const NavbarTemplate: FunctionComponent<{
  leftColumnContent?: React.ReactNode;
  rightColumnContent?: React.ReactNode;
  extraContent?: React.ReactNode;
  banner?: React.ReactNode;
}> = ({
  leftColumnContent = null,
  rightColumnContent = null,
  extraContent = null,
  banner = null,
}) => {
  return (
    <>
      <div className={styles['navbar-spacer']}></div>
      <div className={`${styles['fern-navbar']} navbar`} title="Navigation">
        <div className={styles['fern-navbar--left-column']}>
          {leftColumnContent}
        </div>
        <NavbarLogo></NavbarLogo>
        <div className={styles['fern-navbar--right-column']}>
          {rightColumnContent}
        </div>
        {extraContent}
      </div>
      {banner}
    </>
  );
};
