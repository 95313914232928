import { Navbar } from '@components/navbar/navbar';
import React, { FunctionComponent } from 'react';
import dynamic from 'next/dynamic';
import { LoadOnInteraction } from '@shared_components/load_on_interaction/load_on_interaction';

const TrackingModeModal = dynamic<{}>(
  () =>
    import('@components/Admin/tracking_mode_modal/tracking_mode_modal').then(
      (module) => module.TrackingModeModal
    ),
  {
    ssr: false,
  }
);
const ImpersonateWidget = dynamic<{}>(
  () =>
    import('@components/Admin/impersonate_widget/impersonate_widget').then(
      (module) => module.ImpersonateWidget
    ),
  { ssr: false }
);
const Footer = dynamic<{}>(
  () => import('@components/footer/footer').then((module) => module.Footer),
  { ssr: false }
);

export const NavAndFooter: FunctionComponent<{ hideNav?: boolean }> = ({
  hideNav = false,
  children,
}) => {
  return (
    <>
      <header>
        <Navbar hideNav={hideNav} />
        <LoadOnInteraction>
          <TrackingModeModal />
          <ImpersonateWidget />
        </LoadOnInteraction>
      </header>
      <main>{children}</main>
      <LoadOnInteraction>
        <Footer />
      </LoadOnInteraction>
    </>
  );
};
