import React, { FunctionComponent } from 'react';
import styles from './collapse_caret.module.css';
import classNamesBind from 'classnames/bind';
import { CaretDown } from '@shared_components/icons/caret-down';

const classNames = classNamesBind.bind(styles);
/**
 * to change the size of this icon, use one of the font css classes
 * @param className use this to change the font size
 */
export const CollapseCaret: FunctionComponent<{
  expanded: boolean;
  size?: 'small' | 'large';
}> = ({ expanded, size = 'small' }) => {
  return (
    <div
      className={classNames({
        'collapse-caret': true,
        expanded,
      })}
    >
      <CaretDown
        className={size === 'small' ? 'w-[12px] h-[12px]' : 'w-[16px] h-[16px]'}
      />
    </div>
  );
};
