import getConfig from 'next/config';
import { HttpService } from '../http_service';
import {
  ButterBlogPostDetailResponse,
  ButterBlogPostIndexResponse,
} from './blog.interface';
import {
  ButterHomepageResponse,
  HOMEPAGE_SLUG,
} from './home_page_response.interface';
import { LandingPageResponse } from './landing_page.interface';
import {
  ButterRoomTypePage,
  ButterRoomTypePageResponse,
} from './room_type_page.type';
import {
  ALLITEMS_SLUG,
  ButterAllItemsPageResponse,
} from './all_items_page.interface';
import { Brand } from '@utility_functions/getBrandProps';

const { publicRuntimeConfig } = getConfig();

const baseUrl = 'https://api.buttercms.com/v2';
const publicButterApiToken = `auth_token=${publicRuntimeConfig.butterCmsApiKey}`;
const preview: '1' | '0' = publicRuntimeConfig.butterCmsPreviewMode;
export const DEFAULT_BLOG_PAGE_SIZE = 10;

function getBlogPostList(
  page: string = '1',
  pageSize = DEFAULT_BLOG_PAGE_SIZE
): Promise<ButterBlogPostIndexResponse> {
  return HttpService.get(
    `${baseUrl}/posts?page=${page}&${publicButterApiToken}&page_size=${pageSize}&preview=${preview}&exclude_body=true`
  );
}

function getBlogPost(slug: string): Promise<ButterBlogPostDetailResponse> {
  // note preview doesn't matter here
  return HttpService.get(`${baseUrl}/posts/${slug}?${publicButterApiToken}`);
}

function getLandingPage(slug: string): Promise<LandingPageResponse> {
  return HttpService.get(
    `${baseUrl}/pages/landing-page/${slug}?${publicButterApiToken}&preview=${preview}`
  );
}

let cachedHomePage: { respose: ButterHomepageResponse; brand: Brand } | null =
  null;

/**
 * Home page is set up as a special page in buttercms
 */
function getHomePage(brandName: Brand): Promise<ButterHomepageResponse> {
  if (cachedHomePage && cachedHomePage.brand === brandName) {
    console.count('Using cached ButterCMS Hompage Response');
    return Promise.resolve(cachedHomePage.respose);
  }

  console.count('Fetching ButterCMS Hompage');
  return HttpService.get(
    `${baseUrl}/pages/*/${HOMEPAGE_SLUG}-${brandName}?${publicButterApiToken}&preview=${preview}`
  ).then((res: ButterHomepageResponse) => {
    cachedHomePage = { respose: res, brand: brandName };

    // invalidate cache
    setTimeout(() => {
      console.count('Invalidating ButterCMS homepage cache');
      cachedHomePage = null;
    }, 1000 * (publicRuntimeConfig.appName === 'Production' ? 60 * 60 : 5));
    return res;
  });
}

function getRoomTypePage(slug: string): Promise<ButterRoomTypePage | null> {
  return HttpService.get(
    `${baseUrl}/pages/room_types/${slug}?${publicButterApiToken}&preview=${preview}`
  )
    .then((res: ButterRoomTypePageResponse) => res.data)
    .catch((_err) => {
      // don't want to throw a 404, which triggers a catch
      return null;
    });
}

function getAllItemsPage(
  brandName: Brand
): Promise<ButterAllItemsPageResponse> {
  return HttpService.get(
    `${baseUrl}/pages/*/${ALLITEMS_SLUG}-${brandName}?${publicButterApiToken}&preview=${preview}`
  );
}

export const ButterCMSService = {
  getBlogPostList,
  getBlogPost,
  getLandingPage,
  getHomePage,
  getRoomTypePage,
  getAllItemsPage,
};
