export interface RoomTypeTag {
  name: string;
  slug: string;
  itemTypes: HardcodedItemType[];
}

/**
 * this is only for the hard-coded item types in the navbar
 * eventually, we might get this data from the backend.
 */
export interface HardcodedItemType {
  name: string;
  slug: string;
}

const livingRoom: RoomTypeTag = {
  name: 'Living Room',
  slug: 'living-room',
  itemTypes: [
    { name: 'Sofas & Sectionals', slug: 'sofas-sectionals' },
    { name: 'Accent Chairs', slug: 'accent-chairs' },
    { name: 'Ottomans & Poufs', slug: 'ottomans-poufs' },
    { name: 'Coffee Tables', slug: 'coffee-tables' },
    { name: 'Side Tables', slug: 'side-tables' },
    { name: 'Consoles & TV Stands', slug: 'sideboards-console-tables' },
  ],
};

const diningRoom: RoomTypeTag = {
  name: 'Dining Room',
  slug: 'dining-room',
  itemTypes: [
    { name: 'Dining Tables', slug: 'dining-tables' },
    { name: 'Dining Chairs', slug: 'dining-chairs' },
    { name: 'Benches', slug: 'benches' },
    { name: 'Bar Stools', slug: 'bar-stools' },
    { name: 'Bar Carts', slug: 'bar-carts' },
    { name: 'Sideboards', slug: 'media-consoles' },
  ],
};

const bedroom: RoomTypeTag = {
  name: 'Bedroom',
  slug: 'bedroom',
  itemTypes: [
    { name: 'Bedframes', slug: 'bedframes' },
    { name: 'Mattresses', slug: 'mattresses' },
    { name: 'Dressers', slug: 'dressers' },
    { name: 'Nightstands', slug: 'nightstands' },
  ],
};

const office: RoomTypeTag = {
  name: 'Office',
  slug: 'offices',
  itemTypes: [
    { name: 'Desks', slug: 'desks' },
    { name: 'Office Chairs', slug: 'office-chairs' },
    { name: 'Shelving', slug: 'bookshelves' },
  ],
};

const moreItemTypes: HardcodedItemType[] = [
  { name: 'Lighting', slug: 'lighting' },
  { name: 'Rugs', slug: 'rugs' },
  { name: 'Pillows & Throws', slug: 'pillows-throws' },
  { name: 'Bedding & Bath', slug: 'bedding-linens' },
  { name: 'Mirrors', slug: 'mirrors' },
  { name: 'Wall Art', slug: 'wall-art' },
  { name: 'Tabletop', slug: 'tabletop' },
  { name: 'TVs', slug: 'electronics' },
  { name: 'Outdoor', slug: 'outdoor' },
];

/**
 * @deprecated
 */
export const DeprecatedRoomTypesService = {
  hardcodedTags: {
    livingRoom,
    diningRoom,
    bedroom,
    office,
    moreItemTypes,
  },
};
