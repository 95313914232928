import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const User: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.75 4.875C6.75 3.33977 7.83796 2.25 9 2.25C10.162 2.25 11.25 3.33977 11.25 4.875C11.25 6.41023 10.162 7.5 9 7.5C7.83796 7.5 6.75 6.41023 6.75 4.875ZM9 0.75C6.84833 0.75 5.25 2.68231 5.25 4.875C5.25 7.06769 6.84833 9 9 9C11.1517 9 12.75 7.06769 12.75 4.875C12.75 2.68231 11.1517 0.75 9 0.75ZM3.75 14.25C3.75 14.0978 3.8216 13.8584 4.06142 13.553C4.29695 13.253 4.66119 12.9345 5.14531 12.6419C6.11307 12.057 7.47875 11.625 9 11.625C10.5213 11.625 11.8869 12.057 12.8547 12.6419C13.3388 12.9345 13.7031 13.253 13.9386 13.553C14.1784 13.8584 14.25 14.0978 14.25 14.25C14.25 14.7554 14.1119 14.9752 13.9794 15.0959C13.8189 15.242 13.5325 15.3651 13.032 15.4277C12.5379 15.4894 11.9378 15.4811 11.2343 15.4518C11.0691 15.4449 10.8981 15.4369 10.7229 15.4286L10.7228 15.4286C10.1743 15.4028 9.58417 15.375 9 15.375C8.41583 15.375 7.8257 15.4028 7.27717 15.4286L7.27713 15.4286C7.10188 15.4369 6.93087 15.4449 6.76565 15.4518C6.06221 15.4811 5.46208 15.4894 4.96803 15.4277C4.46754 15.3651 4.18106 15.242 4.02062 15.0959C3.88814 14.9752 3.75 14.7554 3.75 14.25ZM9 10.125C7.20754 10.125 5.57322 10.6305 4.36941 11.3581C3.76775 11.7218 3.25331 12.1532 2.8816 12.6267C2.51418 13.0947 2.25 13.6522 2.25 14.25C2.25 15.0571 2.48686 15.7279 3.01063 16.2049C3.50644 16.6564 4.15746 16.838 4.78197 16.9161C5.41292 16.995 6.12529 16.9798 6.8281 16.9505C7.01006 16.9429 7.1917 16.9344 7.37327 16.9259L7.37441 16.9258C7.91479 16.9004 8.45449 16.875 9 16.875C9.54551 16.875 10.0852 16.9004 10.6256 16.9258L10.6268 16.9259C10.8083 16.9344 10.9899 16.9429 11.1719 16.9505C11.8747 16.9798 12.5871 16.995 13.218 16.9161C13.8425 16.838 14.4936 16.6564 14.9894 16.2049C15.5131 15.7279 15.75 15.0571 15.75 14.25C15.75 13.6522 15.4858 13.0947 15.1184 12.6267C14.7467 12.1532 14.2323 11.7218 13.6306 11.3581C12.4268 10.6305 10.7925 10.125 9 10.125Z"
      />
    </svg>
  );
};
