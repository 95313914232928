import { HttpService } from './http_service';
import { SegmentService } from './segment_service';

interface Contact {
  email: string;
  phone?: string;
}

function postContact(contact: Contact) {
  return HttpService.post('/api/v1/public/customer_leads', contact);
}

export type ZipCodeResponseTypes = 'undeliverable' | 'deliverable';
interface CheckZipcodeResponse {
  status: ZipCodeResponseTypes;
}
function checkZipcode(zip: string): Promise<CheckZipcodeResponse> {
  return HttpService.post('/api/v1/public/customer_leads/check_zip', {
    zip,
  })
    .then((response) => {
      SegmentService.trackEngagementEvent('Zipcode Checked', {
        response: response.status,
        zip_submitted: zip,
      });
      return response;
    })
    .catch((error) => {
      SegmentService.trackEngagementEvent('Zipcode Checked', {
        response: 'error',
        zip_submitted: zip,
      });
      return error;
    });
}

interface ZipLeadResponse {
  success: boolean;
}
function submitZipLead(zip: string, email: string): Promise<ZipLeadResponse> {
  return HttpService.post('/api/v1/public/customer_leads/create_lead', {
    zip,
    email,
  });
}

export const CustomerLeadsService = {
  postContact,
  checkZipcode,
  submitZipLead,
};
