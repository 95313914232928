import { HeroWidgetProps } from '@shared_components/butter_cms/hero_widget';
import { makeMock } from '@utility_functions/makeMock';
import { SEO } from './landing_page.interface';

type HomepageSlug = 'home-page';
export const HOMEPAGE_SLUG: HomepageSlug = 'home-page';

export type ButterHomepage = {
  slug: HomepageSlug;
  name: string;
  published: null | boolean;
  updated: string;
  page_type: null;
  fields: {
    seo: SEO;
    hero: HeroWidgetProps;
  };
};

export type ButterHomepageResponse = {
  data: ButterHomepage;
};

export const mockButterHomepage = makeMock<ButterHomepage>({
  slug: 'home-page',
  name: 'Home Page',
  published: null,
  updated: '2022-11-23T23:28:37.852735Z',
  page_type: null,
  fields: {
    seo: {
      title: 'Fernish: Premium furniture rentals that feel like home',
      description:
        'Enjoy premium furniture & décor rentals, delivered and assembled in a week. Pay monthly, and decide later if you want to keep, return, or swap your pieces.',
    },
    hero: {
      headline: '75% off – our Biggest Sale of the year',
      subheadline:
        '<p>Save big on your first month of furniture and d&eacute;cor rental during the Black Friday Sale*</p>',
      image: 'https://cdn.buttercms.com/TogoePtTVi04vwNgai4T',
      button_label: 'Start shopping',
      button_url: '/n/room_types',
    },
  },
});

export const mockButterHomepageResponse = makeMock<ButterHomepageResponse>({
  data: mockButterHomepage({}),
});
