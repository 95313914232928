import { useOnScrollOrClick } from '@custom_hooks/use_on_scroll_or_click';
import { DeprecatedRoomTypesService } from '@services/deprecated_room_types_service';
import { FunctionComponent, useEffect, useRef } from 'react';
import { DesignInspirationLinks } from './design_inspiration_link/design_inspiration_link';
import { EasyPackageHardcodedLink } from './easy_package_hardcoded_link';
import { ItemTypeLink } from './item_type_link';
import { RoomTypeTagLink } from './room_type_tag_link';
import { NavLinkWithRightArrow } from './shop_all_item_types_link';

export const TheGoodsFlyout: FunctionComponent<{}> = () => {
  const { livingRoom, diningRoom, bedroom, office, moreItemTypes } =
    DeprecatedRoomTypesService.hardcodedTags;

  return (
    <div className="fern-grid--content">
      <div className="col-span-2">
        {/* col 1 */}
        <ItemTypeLinkContainer className="mb-45">
          <RoomTypeTagLink roomTypeTag={livingRoom}></RoomTypeTagLink>
          {livingRoom.itemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}
        </ItemTypeLinkContainer>

        <div className="space-y-45">
          <NavLinkWithRightArrow
            text="Shop all items"
            href="/n/room_types"
            segmentAction="NavBar ViewAllLinkClicked"
            externalLink={false}
          />
          <NavLinkWithRightArrow
            text="Take Our Virtual Design Quiz"
            href="/virtual-design-quiz"
            segmentAction="NavBar ViewAllLinkClicked"
            externalLink={true}
          />
        </div>
      </div>

      <div className="col-span-2">
        {/* col 2 */}
        <ItemTypeLinkContainer>
          <RoomTypeTagLink roomTypeTag={diningRoom}></RoomTypeTagLink>
          {diningRoom.itemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}
        </ItemTypeLinkContainer>
      </div>

      <div className="col-span-2">
        {/* col 3 */}
        <ItemTypeLinkContainer className="mb-45">
          <RoomTypeTagLink roomTypeTag={bedroom}></RoomTypeTagLink>
          {bedroom.itemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}
        </ItemTypeLinkContainer>

        <ItemTypeLinkContainer>
          <RoomTypeTagLink roomTypeTag={office}></RoomTypeTagLink>
          {office.itemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}
        </ItemTypeLinkContainer>
      </div>

      <div className="col-span-2">
        {/* col 4 */}
        <ItemTypeLinkContainer>
          <div className="fern-small-label mt-5">More</div>
          {moreItemTypes.map((itemType, i) => (
            <ItemTypeLink itemType={itemType} key={i}></ItemTypeLink>
          ))}
          <EasyPackageHardcodedLink />
        </ItemTypeLinkContainer>
      </div>

      <div className="col-span-4">
        {/* col 5 */}
        <DesignInspirationLinks />
      </div>
    </div>
  );
};

const ItemTypeLinkContainer: FunctionComponent<{ className?: string }> = ({
  children,
  className = '',
}) => {
  return <div className={[className, 'space-y-15'].join(' ')}>{children}</div>;
};
