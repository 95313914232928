import { useState } from 'react';
import { SegmentService } from '@services/segment_service';

type openModal = () => void;
type closeModal = () => void;

export function useModal({
  openDefault = false,
  segmentEventContext,
  segmentData = {},
}: {
  openDefault?: boolean;
  segmentEventContext: string;
  segmentData?: any;
}): {
  open: boolean;
  openModal: openModal;
  closeModal: closeModal;
  segmentEventContext: string;
  segmentData: any;
} {
  const [open, setOpen] = useState(openDefault);

  function openModal() {
    SegmentService.trackEngagementEvent(`${segmentEventContext} Opened`, {
      ...segmentData,
    });
    setOpen(true);
  }

  function closeModal() {
    SegmentService.trackEngagementEvent(`${segmentEventContext} Closed`, {
      ...segmentData,
    });
    setOpen(false);
  }

  return { open, openModal, closeModal, segmentEventContext, segmentData };
}
