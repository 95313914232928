import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const HeartOutline: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M13.3 2.5C14.15 2.5 14.95 2.84 15.55 3.46C16.82 4.76 16.82 6.86 15.55 8.16L14.58 9.16L9 14.85L3.42 9.15L2.45 8.15C1.18 6.85 1.18 4.75 2.45 3.45C3.05 2.84 3.85 2.5 4.7 2.5C5.55 2.5 6.35 2.84 6.95 3.46L7.92 4.46L9 5.55L10.07 4.45L11.04 3.45C11.65 2.84 12.45 2.5 13.3 2.5ZM13.3 1C12.1 1 10.89 1.47 9.98 2.41L9 3.4L8.03 2.4C7.11 1.47 5.9 1 4.7 1C3.5 1 2.3 1.47 1.38 2.41C-0.46 4.29 -0.46 7.33 1.38 9.21L2.35 10.21L9 17L15.65 10.2L16.62 9.2C18.46 7.32 18.46 4.28 16.62 2.4C15.7 1.47 14.5 1 13.3 1Z" />
    </svg>
  );
};
