import { SegmentService } from '@services/segment_service';
import Image from 'next/image';
import { FunctionComponent } from 'react';
import styles from './fern-image.module.css';
import missing_1x1 from './missing_1x1.svg';
import missing_2x1 from './missing_2x1.svg';
import missing_2x3 from './missing_2x3.svg';
import missing_3x2 from './missing_3x2.svg';
import missing_3x4 from './missing_3x4.svg';
import missing_4x3 from './missing_4x3.svg';

type ImageSizes =
  | 'fern-image--4x3'
  | 'fern-image--3x4'
  | 'fern-image--3x2'
  | 'fern-image--2x3'
  | 'fern-image--2x1'
  | 'fern-image--1x1';

export const FernImage: FunctionComponent<{
  imageUrl: string | null;
  altText: string;
  className: ImageSizes;
  addClass?: string;
  nextJSImageSizes?: string;
  nextJSImagePriority?: boolean;
  nextJSImageQuality?: number;
}> = ({
  imageUrl: _imageUrl,
  altText,
  className,
  addClass = '',
  nextJSImageSizes = '',
  nextJSImagePriority,
  nextJSImageQuality = 90,
}) => {
  if (!_imageUrl) {
    const metaData = {
      page_title: document?.title,
      pathname: location?.pathname,
      href: location?.href,
      page_location: location?.href,
      altText,
      _imageUrl,
    };
    SegmentService.trackEngagementEvent('Missing Image', metaData);

    return (
      <FernStaticImage
        imageUrl={getMissingImage(className)}
        altText={altText}
        className={className}
      />
    );
  }

  let imageUrl = _imageUrl[0] === 'h' ? _imageUrl : 'https:' + _imageUrl;
  imageUrl = imageUrl.replace(
    /http:\/\/localhost:3000/g,
    'http://web-dev:3000'
  );

  return (
    <div
      className={[addClass, styles['fern-image'], styles[className]].join(' ')}
    >
      <Image
        src={imageUrl}
        style={{ objectFit: 'cover' }}
        fill
        alt={altText}
        // https://nextjs.org/docs/api-reference/next/image#sizes
        sizes={nextJSImageSizes}
        priority={nextJSImagePriority}
        quality={nextJSImageQuality}
      />
    </div>
  );
};

export const FernStaticImage: FunctionComponent<{
  imageUrl: StaticImageData;
  altText: string;
  className: ImageSizes;
  addClass?: string;
  imgClass?: string;
}> = ({ imageUrl, altText, className, addClass = '', imgClass = '' }) => {
  return (
    <div
      className={[addClass, styles['fern-image'], styles[className]].join(' ')}
    >
      <Image
        className={imgClass}
        src={imageUrl}
        alt={altText}
        style={{ objectFit: 'cover' }}
        fill
      />
    </div>
  );
};

function getMissingImage(className: ImageSizes): StaticImageData {
  switch (className) {
    case 'fern-image--1x1':
      return missing_1x1;
    case 'fern-image--2x1':
      return missing_2x1;
    case 'fern-image--3x4':
      return missing_3x4;
    case 'fern-image--4x3':
      return missing_4x3;
    case 'fern-image--2x3':
      return missing_2x3;
    case 'fern-image--3x2':
      return missing_3x2;
    default:
      return missing_1x1;
  }
}
