import { RoomTypeWidget } from '@shared_components/butter_cms/room_type_tiles';
import { makeMock } from '@utility_functions/makeMock';
import { SEO } from './landing_page.interface';

type AllItemsSlug = 'all-items';
export const ALLITEMS_SLUG: AllItemsSlug = 'all-items';

export interface ButterAllItemsPage {
  slug: AllItemsSlug;
  name: string;
  published: null | boolean;
  updated: string;
  page_type: null;
  fields: {
    seo: SEO;
    body: RoomTypeWidget[];
  };
}

export type ButterAllItemsPageResponse = {
  data: ButterAllItemsPage;
};

export const mockAllItems = makeMock<ButterAllItemsPage>({
  slug: 'all-items',
  name: 'All Items',
  published: null,
  updated: '2022-12-30T15:52:21.759001Z',
  page_type: null,
  fields: {
    seo: {
      title: 'All Items',
      description: '',
    },
    body: [
      {
        type: 'room_type_widget',
        fields: {
          slides: [
            {
              title: 'Living Room',
              image: 'https://cdn.buttercms.com/VXRqijAxS42wsYd4XEc1',
              url: '/n/room_types/living-room',
            },
            {
              title: 'Bedroom',
              image: 'https://cdn.buttercms.com/vLDlwwr0Ry210smRQ9SE',
              url: '/n/room_types/bedroom',
            },
            {
              title: 'Offices',
              image: 'https://cdn.buttercms.com/CD5pSluuSQqsdDxciiCs',
              url: '/n/room_types/offices',
            },
            {
              title: 'Dining Room',
              image: 'https://cdn.buttercms.com/Y3rJo5wQdW1wlaXItuOg',
              url: '/n/room_types/dining-room',
            },
          ],
        },
      },
    ],
  },
});
