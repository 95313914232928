import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const CaretRight: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 10 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.44947 15.7735C0.738574 16.0701 1.21341 16.0762 1.51004 15.7871L9.49996 8L1.51004 0.212897C1.21341 -0.0762062 0.738574 -0.0701027 0.44947 0.22653C0.160365 0.523164 0.166469 0.997999 0.463103 1.2871L7.35083 8L0.463103 14.7129C0.166469 15.002 0.160365 15.4768 0.44947 15.7735Z"
      />
    </svg>
  );
};
