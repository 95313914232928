import { useSessionData } from '@custom_hooks/use_session_data';
import { FunctionComponent } from 'react';
import { ItemTypeLink } from './item_type_link';

export const EasyPackageHardcodedLink: FunctionComponent<{}> = () => {
  const { data: sessionData } = useSessionData();
  const { currentUser } = sessionData || {};

  if (!currentUser) {
    return <></>;
  }

  if (currentUser.isB2bUser || currentUser.isAdminUser) {
    return (
      <ItemTypeLink
        itemType={{ slug: 'easy-packages', name: 'Easy Packages' }}
      />
    );
  }

  return <></>;
};
