import { useEffect, useRef, useState } from 'react';

export function useOnMouseover() {
  const [mouseOvered, setMouseOvered] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current || mouseOvered) {
      return;
    }
    function onHover() {
      setMouseOvered(true);
      cleanup();
    }
    ref.current.addEventListener('mouseover', onHover);

    function cleanup() {
      ref.current?.removeEventListener('mouseover', onHover);
    }

    return cleanup;
  }, []);

  return { ref, mouseOvered };
}
