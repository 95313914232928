import React, { FunctionComponent } from 'react';
import { SegmentService } from '@services/segment_service';
import styles from './nav-mobile-menu-icon.module.css';
import classNamesBind from 'classnames/bind';
const classNames = classNamesBind.bind(styles);

export const MobileMenuIcon: FunctionComponent<{
  open: boolean;
  onClick: () => void;
}> = ({ open, onClick }) => {
  function handleClick() {
    if (open) {
      SegmentService.trackEngagementEvent('NavBarMobile Closed', {});
    } else {
      SegmentService.trackEngagementEvent('NavBarMobile Opened', {});
    }
    onClick();
  }

  return (
    <button
      className={classNames({ 'fern-mobile-menu-icon': true, active: open })}
      title="menu"
      onClick={handleClick}
    ></button>
  );
};
