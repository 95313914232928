import React from 'react';
import { Transition } from 'react-transition-group';
import {
  EXITED,
  ENTERED,
  ENTERING,
  EXITING,
} from 'react-transition-group/Transition';
import styles from './collapse.module.css';

function getHeight(elem: HTMLElement) {
  const offsetHeight = elem.offsetHeight;
  const marginTop = elem.style.marginTop || '0';
  const marginBottom = elem.style.marginBottom || '0';

  return offsetHeight + parseInt(marginTop, 10) + parseInt(marginBottom, 10);
}

interface Props {
  in: boolean;
  id: string;
  ariaLabelledBy: string;
  timeout?: number;
}

interface State {}

export class Collapse extends React.Component<Props, State> {
  nodeRef = React.createRef<HTMLElement>();
  static defaultProps = {
    timeout: 300,
  };

  collapseStyles = {
    [EXITING]: styles['fern-collapsing'],
    [EXITED]: styles['fern-collapse'],
    [ENTERING]: styles['fern-collapsing'],
    [ENTERED]: `${styles['fern-collapse']} ${styles.in}`,
  };

  /* -- Expanding -- */
  handleEnter = () => {
    if (this.nodeRef.current) {
      this.nodeRef.current.style.height = '0';
    }
  };

  handleEntering = () => {
    if (!this.nodeRef.current) {
      return;
    }
    this.nodeRef.current.style.height = `${this.nodeRef.current.scrollHeight}px`;
  };

  handleEntered = () => {
    if (!this.nodeRef.current) {
      return;
    }
    this.nodeRef.current.style.height = '';
  };

  /* -- Collapsing -- */
  handleExit = () => {
    if (!this.nodeRef.current) {
      return;
    }
    this.nodeRef.current.style.height = getHeight(this.nodeRef.current) + 'px';
    // calling offsetHeight makes the exit work smoothly for some reason
    this.nodeRef.current.offsetHeight; // eslint-disable-line no-unused-expressions
  };

  handleExiting = () => {
    if (!this.nodeRef.current) {
      return;
    }
    this.nodeRef.current.style.height = '0';
  };

  render() {
    return (
      <Transition
        in={this.props.in}
        nodeRef={this.nodeRef}
        timeout={this.props.timeout}
        onEnter={this.handleEnter}
        onEntering={this.handleEntering}
        onEntered={this.handleEntered}
        onExit={this.handleExit}
        onExiting={this.handleExiting}
        addEndListener={() => {}}
      >
        {(state: keyof typeof this.collapseStyles, props: any) => {
          return (
            <div
              ref={this.nodeRef}
              className={this.collapseStyles[state]}
              style={{
                transition: `height ${this.props.timeout}ms ease`,
              }}
              id={this.props.id}
              aria-labelledby={this.props.ariaLabelledBy}
              role="region"
              {...props}
            >
              {this.props.children}
            </div>
          );
        }}
      </Transition>
    );
  }
}
