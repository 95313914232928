import React, { FunctionComponent } from 'react';
import { RoomTypeTag } from '@services/deprecated_room_types_service';
import { SegmentService } from '@services/segment_service';
import Link from 'next/link';

export const RoomTypeTagLink: FunctionComponent<{
  roomTypeTag: RoomTypeTag;
  className?: string;
}> = ({ roomTypeTag, className = '' }) => {
  return (
    <Link
      href={`/n/room_types/${roomTypeTag.slug}`}
      className="fern-large-body underline"
      onClick={() =>
        SegmentService.trackEngagementEvent('Navbar RoomTypeClicked', {})
      }
    >
      {roomTypeTag.name}
    </Link>
  );
};
