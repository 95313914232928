import React, { FunctionComponent } from 'react';
import { SegmentService } from '@services/segment_service';
import { Wordmark } from '@shared_components/wordmark/wordmark';
import Link from 'next/link';

export const NavbarLogo: FunctionComponent<{}> = () => {
  return (
    <Link href="/">
      <Wordmark />
    </Link>
  );
};
