import { CartKey } from '@custom_hooks/use_cart';
import { SessionDataKey } from '@custom_hooks/use_session_data';
import { SessionData } from '@services/session_service';
import { ToasterContext } from '@shared_components/toaster/toaster';
import { useContext } from 'react';
import { useQueryClient } from 'react-query';
import { useModal } from '../use_modal';
import { pathNameToPageName } from '@utility_functions/getPageName';
import { SegmentService } from '@services/segment_service';
import { FilteredItemsKey } from '@custom_hooks/use_filtered_items';
import { ItemDetailsBaseKey } from '@custom_hooks/use_item_details';

export const CHECK_DELIVERY_AREA = 'Check Delivery Area';

export function getLocationModalText(
  sessionData: SessionData | null | undefined
): string {
  let locationModalText = CHECK_DELIVERY_AREA;

  if (sessionData?.zipCode) {
    if (sessionData.deliverable) {
      locationModalText = sessionData.currentMarket.name;
    } else {
      locationModalText = 'Outside Delivery Area';
    }
  } else {
    locationModalText = CHECK_DELIVERY_AREA;
  }
  return locationModalText;
}

export function useLocationCaptureModal() {
  const queryClient = useQueryClient();

  const toaster = useContext(ToasterContext);

  const locationCaptureModal = useModal({
    segmentEventContext: 'Desktop Location Capture Modal',
  });

  function openLocationModal(component: string) {
    SegmentService.trackEngagementEvent('Location Modal opened', {
      page_location: pathNameToPageName(location.pathname),
      button_location: component,
    });
    locationCaptureModal.openModal();
  }

  function handleZipCodeWithinDeliveryArea() {
    queryClient.invalidateQueries(SessionDataKey);
    queryClient.invalidateQueries(CartKey);
    queryClient.invalidateQueries(ItemDetailsBaseKey);
    queryClient.invalidateQueries(FilteredItemsKey);

    locationCaptureModal.closeModal();
    toaster.addBread({
      type: 'notice',
      message: 'Great, we deliver to your area!',
    });
  }

  function handleZipCodeOutsideDeliveryArea() {
    queryClient.invalidateQueries(SessionDataKey);
  }

  return {
    openLocationModal,
    locationCaptureModal,
    handleZipCodeWithinDeliveryArea,
    handleZipCodeOutsideDeliveryArea,
  };
}
