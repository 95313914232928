import { useSessionData } from '@custom_hooks/use_session_data';
import { SegmentService } from '@services/segment_service';
import Image from 'next/image';
import { FunctionComponent } from 'react';
import curatedPackagesImg from './curated-packages.jpg';
import designQuizImg from './design-quiz.jpg';
import styles from './design_inspiration_link.module.css';
import inspirationImg from './inspiration.jpg';
import { useBrand } from '@utility_functions/getBrandProps';

export const DesignInspirationLinks: FunctionComponent = () => {
  const { data: sessionData } = useSessionData();
  const { brand } = useBrand();

  const links: DesignInspirationLinkProps[] = [
    {
      image: designQuizImg,
      title: 'Take the quiz',
      body: 'Let our virtual designer do the work for you!',
      href: '/virtual-design-quiz',
      segmentEventContext: 'Navbar Take The Quiz',
    },
    {
      image: inspirationImg,
      title: 'Explore the gallery',
      body: 'Discover the perfect look for your home.',
      href: '/inspiration-corner',
      segmentEventContext: 'Navbar Explore the Gallery',
    },
  ];

  if (sessionData?.currentUser?.isB2bUser && brand?.isFernish) {
    links.push({
      image: curatedPackagesImg,
      title: 'Browse packages',
      body: 'Check out our curated whole-home packages.',
      href: '/n/item_types/easy-packages/items',
      newWindow: true,
      segmentEventContext: 'Navbar Whole Home Packages',
    });
  }

  return (
    <div className="fern-nav--design-inspiration">
      <div className="small-desktop:mt-5 fern-small-label mb-15">
        Design Inspiration
      </div>

      <div style={{ display: 'grid', rowGap: 30 }}>
        {links.map((link, i) => (
          <DesignInspirationLink key={i} {...link} />
        ))}
      </div>
    </div>
  );
};

type DesignInspirationLinkProps = {
  image: StaticImageData;
  title: string;
  body: string;
  href: string;
  newWindow?: boolean;
  segmentEventContext: string;
};

export const DesignInspirationLink: FunctionComponent<
  DesignInspirationLinkProps
> = ({ image, title, body, href, newWindow, segmentEventContext }) => {
  const largeLink = <div className="fern-large-link-button">{title}</div>;
  return (
    <div>
      <a
        className={styles.linkGrid}
        href={href}
        target={newWindow ? '_blank' : ''}
        onClick={() =>
          SegmentService.trackEngagementEvent(
            `${segmentEventContext} Clicked`,
            {}
          )
        }
        rel="noreferrer"
      >
        <Image src={image} alt={title} />
        <div>
          <div>{body}</div>
          <div className="fern-show-desktop-up">{largeLink}</div>
        </div>
        <div className="fern-hide-desktop-up col-span-full">{largeLink}</div>
      </a>
    </div>
  );
};
