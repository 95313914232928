import { FormEvent, useState } from 'react';
import {
  CustomerLeadsService,
  ZipCodeResponseTypes,
} from '@services/customer_leads_service';

export function useZipChecker(
  handleInDeliveryArea: () => void,
  handleOutsideDeliveryArea: () => void
) {
  const [formState, setFormState] = useState<
    ZipCodeResponseTypes | 'unchecked' | 'error' | 'lead-collected'
  >('unchecked');
  const [loading, setLoading] = useState(false);
  // inputs
  const [zipcode, setZipcode] = useState('');
  const [zipcodeError, setZipcodeError] = useState('');
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  function handleSubmitZipCheck(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!zipcode) {
      return setZipcodeError('Please enter a valid zip code');
    }
    setLoading(true);
    CustomerLeadsService.checkZipcode(zipcode)
      .then((res) => {
        setFormState(res.status);
        setZipcode('');
        if (res.status === 'deliverable') {
          handleInDeliveryArea();
          setFormState('unchecked');
        } else {
          handleOutsideDeliveryArea();
        }
      })
      .catch((_err) => {
        setFormState('error');
      })
      .then(() => setLoading(false));
  }
  function handleSubmitEmailLead(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    if (!email) {
      return setEmailError('Please enter a valid email');
    }
    setLoading(true);
    CustomerLeadsService.submitZipLead(zipcode, email)
      .then((res) => {
        if (res.success) {
          setFormState('lead-collected');
        } else {
          setFormState('error');
        }
      })
      .catch((_err) => {
        setFormState('error');
      })
      .then(() => setLoading(false));
  }
  return {
    email,
    emailError,
    formState,
    setFormState,
    loading,
    handleSubmitEmailLead,
    handleSubmitZipCheck,
    setEmail,
    setZipcode,
    zipcode,
    zipcodeError,
  };
}
