import React, { FunctionComponent } from 'react';
import { SegmentService } from '@services/segment_service';
import { CaretRight } from '@shared_components/icons/caret-right';
import Link from 'next/link';

export const NavLinkWithRightArrow: FunctionComponent<{
  text: string;
  href: string;
  segmentAction: string;
  className?: string;
  externalLink: boolean;
}> = ({ text, href, segmentAction, className, externalLink }) => {
  const linkContent = (
    <>
      <div className="fern-clean-link mr-15">{text}</div>
      <div className="bg-sky flex justify-center items-center rounded-full w-[24px] h-[24px] min-w-[24px]">
        <CaretRight className="text-white" style={{ height: 10, width: 10 }} />
      </div>
    </>
  );

  const aTagAttributes = {
    className: ['flex items-center', className].join(' '),
    onClick: () => SegmentService.trackEngagementEvent(segmentAction, {}),
  };

  return (
    <>
      {externalLink ? (
        <a {...aTagAttributes} href={href}>
          {linkContent}
        </a>
      ) : (
        <Link href={href} {...aTagAttributes}>
          {linkContent}
        </Link>
      )}
    </>
  );
};
