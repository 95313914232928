import { ButterCMSService } from '@services/butter_cms_service';
import { ButterBlogPost } from '@services/butter_cms_service/blog.interface';
import { SegmentService } from '@services/segment_service';
import { FernImage } from '@shared_components/fern_image/fern-image';
import { Launch } from '@shared_components/icons/launch';
import { limitCharacters } from '@utility_functions/limitCharacters';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';
import { useQuery } from 'react-query';

export const RecentArticles: FunctionComponent<{}> = () => {
  const query = useQuery(
    'recent_articles',
    () => ButterCMSService.getBlogPostList(),
    {
      staleTime: 1000 * 6000, // 6000 seconds -> basically this never needs to go stale unless the user refreshes the page
    }
  );

  if (!query.data) {
    return <></>;
  }

  let articles: ButterBlogPost[] = query.data.data
    .sort((postA, postB) => {
      return postA.published < postB.published ? 1 : -1;
    })
    .slice(0, 2);

  return (
    <div className="fern-nav--recent-articles-content">
      <div className="fern-small-label  mb-20">Recent Articles</div>
      <div className="space-y-30">
        {articles.map((article, i) => (
          <ArticleLink article={article} key={i}></ArticleLink>
        ))}
      </div>
    </div>
  );
};

export const ArticleLink: FunctionComponent<{
  article: ButterBlogPost;
}> = ({ article }) => {
  return (
    <Link
      href={`/n/blog/${article.slug}`}
      className="grid gap-x-15 grid-cols-2 small-desktop:grid-cols-6 small-desktop:gap-x-30"
      target="_blank"
      onClick={() =>
        SegmentService.trackEngagementEvent('NavBar RecentArticleClicked', {})
      }
    >
      <div className="col-span-1 small-desktop:col-span-2">
        {article.featured_image && (
          <FernImage
            imageUrl={article.featured_image}
            altText={article.featured_image_alt}
            className="fern-image--3x2"
          />
        )}
      </div>
      <div className="col-span-1 small-desktop:col-span-4">
        <div className="fern-link">
          <span className="fern-nav--recent-article-title">
            {article.title}
          </span>
          <span className="fern-hide-small-desktop-up">
            <Launch className="text-dark-brown ml-5" />
          </span>
        </div>
        <div className="fern-show-small-desktop-up">
          {limitCharacters(article.summary, 100)}
          <Launch className="text-dark-brown ml-5" />
        </div>
      </div>
    </Link>
  );
};
