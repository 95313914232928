import React, { FunctionComponent } from 'react';
import { BASE_CLASSES, IconProps } from './common_icon_props';

export const CaretDown: FunctionComponent<IconProps> = ({
  className = '',
  style = {},
}) => {
  return (
    <svg
      className={`${BASE_CLASSES} ${className}`}
      style={style}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.226531 3.5812C-0.070103 3.8703 -0.0762068 4.34514 0.212897 4.64177L8 12.6317L15.7871 4.64177C16.0762 4.34514 16.0701 3.8703 15.7735 3.5812C15.4768 3.29209 15.002 3.2982 14.7129 3.59483L8 10.4826L1.2871 3.59483C0.997999 3.2982 0.523165 3.29209 0.226531 3.5812Z"
        />
      </g>
      <defs>
        <clipPath>
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
