import React, { FunctionComponent } from 'react';
import { RecentArticles } from './recent_articles/recent_articles';
import { TheCompanyLinks } from './the_company_links';

export const TheCompanyFlyout: FunctionComponent<{}> = () => {
  return (
    <div className="fern-grid--content">
      <div className="col-start-3 col-end-5">
        <TheCompanyLinks></TheCompanyLinks>
      </div>
      <div className="col-start-5 col-end-11">
        <RecentArticles></RecentArticles>
      </div>
    </div>
  );
};
