import { ErrorIcon } from '@shared_components/icons/error';
import classNamesBind from 'classnames/bind';
import React, { FunctionComponent } from 'react';
import { Control, useWatch } from 'react-hook-form';
import styles from './text-field.module.css';
let classNames = classNamesBind.bind(styles);

// todo - deprecate this
export const TextField: FunctionComponent<{
  labelText: string;
  errorMessageText?: string | null;
  noNumberArrows?: boolean;
  inputProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
}> = ({ labelText, errorMessageText = '', inputProps, noNumberArrows }) => {
  const id = inputProps.id ?? labelText.toLowerCase().split(' ').join('-');

  return (
    <div>
      <div
        className={classNames({
          'text-field': true,
          entered: !!inputProps.value || !!inputProps.placeholder,
          error: errorMessageText,
          'h-[48px]': true,
          'no-number-arrows': noNumberArrows,
        })}
      >
        <input id={id} {...inputProps} />
        <label htmlFor={id}>{labelText}</label>

        {errorMessageText && (
          <ErrorIcon className="text-pomegranate absolute top-[11px] right-10 !h-[24px] !w-[24px]" />
        )}
      </div>
      {errorMessageText && (
        <div className="ml-15 fern-small-body text-pomegranate">
          {errorMessageText}
        </div>
      )}
    </div>
  );
};

export const TextField2: FunctionComponent<{
  labelText: string;
  errorMessageText?: string | null;
  noNumberArrows?: boolean;
  inputProps: React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;
  control: Control<any>;
  className?: string;
}> = ({
  labelText,
  errorMessageText = '',
  inputProps,
  noNumberArrows,
  control,
  className = '',
}) => {
  const id = inputProps.id ?? labelText.toLowerCase().split(' ').join('-');
  const value = useWatch({ control, name: inputProps.name || '' });

  return (
    <div className={className}>
      <div
        // todo - remove classnames
        className={classNames({
          'text-field': true,
          entered: !!value || !!inputProps.placeholder,
          error: errorMessageText,
          'h-[48px]': true,
          'no-number-arrows': noNumberArrows,
        })}
      >
        <input id={id} {...inputProps} />
        <label htmlFor={id}>{labelText}</label>

        {errorMessageText && (
          <ErrorIcon className="text-pomegranate absolute top-[11px] right-10 !h-[24px] !w-[24px]" />
        )}
      </div>
      {errorMessageText && (
        <div className="ml-15 mt-5 fern-small-body text-pomegranate">
          {errorMessageText}
        </div>
      )}
    </div>
  );
};

export const TextArea: FunctionComponent<{
  labelText: string;
  errorMessageText?: string;
  noNumberArrows?: boolean;
  inputProps: Omit<
    React.DetailedHTMLProps<
      React.InputHTMLAttributes<HTMLTextAreaElement>,
      HTMLTextAreaElement
    >,
    'id' // not letting you pass id here b/c we're setting it from labelText
  >;
}> = ({ labelText, errorMessageText = '', inputProps }) => {
  const id = labelText.toLowerCase().split(' ').join('-');

  return (
    <div>
      <div
        className={classNames({
          'text-field': true,
          entered: true,
          error: errorMessageText,
          'h-auto': true,
        })}
      >
        <textarea id={id} rows={4} {...inputProps} />
        <label htmlFor={id}>{labelText}</label>
      </div>
      {errorMessageText && (
        <div className="ml-15 fern-small-body text-pomegranate">
          {errorMessageText}
        </div>
      )}
    </div>
  );
};

// export const StripeTextField: FunctionComponent<{
//   id: string;
//   label: string;
//   errorMessageText?: string;
// }> = ({ id, label, errorMessageText = '' }) => {
//   useEffect(() => {
//     new FernTextField(document.getElementById(id));
//   }, []);

//   return (
//     <div className="fs-exclude">
//       <div
//         className={classNames({
//           'fern-text-field': true,
//           'error with-icon': !!errorMessageText,
//         })}
//       >
//         <div style={{ position: 'relative' }}>
//           <div id={id} className="pt-15"></div>
//           <div className="fern-notched-outline">
//             <div className="fern-notched-outline--leading"></div>
//             <div className="fern-notched-outline--notch">
//               <label htmlFor={id}>{label}</label>
//             </div>
//             <div className="fern-notched-outline--trailing"></div>
//           </div>
//           {errorIcon}
//         </div>
//         {errorMessageText && (
//           <small style={{ position: 'relative' }}>{errorMessageText}</small>
//         )}
//       </div>
//     </div>
//   );
// };
