import React, { FunctionComponent, useContext } from 'react';
import Image from 'next/image';
import { useBrand } from '@utility_functions/getBrandProps';

export const Wordmark: FunctionComponent<{}> = () => {
  const { renderByBrand } = useBrand();

  return renderByBrand((brand) => (
    <Image
      width="106"
      height="0"
      src={brand.wordmark}
      alt={`${brand.brandTitle} Wordmark`}
    />
  ));
};
