import { useSessionData } from '@custom_hooks/use_session_data';
import { LocalStorageService } from '@services/local_storage_service';
import { SegmentService } from '@services/segment_service';
import Link from 'next/link';
import { FunctionComponent } from 'react';
import styles from './navbar.module.css';

export const UserFlyoutMenu: FunctionComponent<{}> = () => {
  const { data: sessionData } = useSessionData();
  const currentUser = sessionData?.currentUser;

  if (sessionData === null) {
    return <></>;
  }

  if (currentUser) {
    return (
      <div
        className={`${styles['fern-nav--small-flyout']} ${styles['flyout-animation-inactive']} ${styles['logged-in']}`}
      >
        <div className="fern-large-body">Hi, {currentUser.givenName}</div>
        <Link
          className="fern-clean-link"
          href="/users/edit"
          // TODO - https://fernish.atlassian.net/browse/FERN-10336
          // href="/n/dashboard/account_details"
          onClick={() =>
            SegmentService.trackEngagementEvent(
              'Desktop Navbar Account Details Link Clicked',
              {}
            )
          }
        >
          Account Details
        </Link>
        <Link
          className="fern-clean-link"
          href="/n/dashboard"
          onClick={() =>
            SegmentService.trackEngagementEvent(
              'Desktop Navbar Dashboard Link Clicked',
              {}
            )
          }
        >
          Order/Payment History
        </Link>
        {currentUser.hasCompletedOrders && (
          <a
            className="fern-clean-link"
            href="/dashboard/payment_information"
            onClick={() =>
              SegmentService.trackEngagementEvent(
                'Desktop Navbar Payment Info Link Clicked',
                {}
              )
            }
          >
            Payment Information
          </a>
        )}
        {currentUser.hasReferralCode && (
          <Link
            className="fern-clean-link"
            href="/n/dashboard/referral_code"
            onClick={() =>
              SegmentService.trackEngagementEvent(
                'Desktop Navbar Referral Code Link Clicked',
                {}
              )
            }
          >
            Your Referral Code
          </Link>
        )}
        <a
          className="fern-clean-link"
          href="/users/sign_out"
          data-method="delete"
          rel="nofollow"
          onClick={() => {
            SegmentService.trackEngagementEvent(
              'Desktop Navbar Sign Out Link Clicked',
              {}
            );
            LocalStorageService.clear();
          }}
        >
          Sign Out
        </a>
      </div>
    );
  } else {
    return (
      <div
        className={`${styles['fern-nav--small-flyout']} ${styles['flyout-animation-inactive']} ${styles['logged-in']}`}
      >
        <a
          className="fern-clean-link"
          href="/users/sign_up"
          onClick={() =>
            SegmentService.trackEngagementEvent(
              'Desktop Navbar Signup Link Clicked',
              {}
            )
          }
        >
          Create an account
        </a>
        <a
          className="fern-clean-link"
          href="/users/sign_in"
          onClick={() =>
            SegmentService.trackEngagementEvent(
              'Desktop Navbar Login Link Clicked',
              {}
            )
          }
        >
          Log in
        </a>
      </div>
    );
  }
};
