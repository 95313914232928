import React, { FunctionComponent } from 'react';
import styles from './animated_success_icon.module.css';

export const AnimatedSuccessIcon: FunctionComponent<{
  color?: 'aqua' | 'persimmon';
}> = ({ color = 'aqua' }) => {
  // Can't construct class names dynamically:
  //  https://tailwindcss.com/docs/content-configuration#dynamic-class-names
  let strokeClass: string = 'stroke-aqua';
  let fillClass: string = 'fill-aqua';

  if (color === 'persimmon') {
    strokeClass = 'stroke-persimmon';
    fillClass = 'fill-persimmon';
  }

  return (
    <div>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={[styles.icon, fillClass].join(' ')}
          d="M8.80401 12.0708L11.0738 14.3464L17.1616 8L19 9.77225L11.1082 18L7 13.8794L8.80401 12.0708Z"
        />
        <circle
          className={[styles.circle, strokeClass].join(' ')}
          cx="12.5"
          cy="12.5"
          r="11.75"
          strokeWidth="1.5"
        />
      </svg>
    </div>
  );
};
